div.gsc-orderby {
  display: none;
}

input.gsc-input {
  background: none !important;
  text-indent: 0 !important;
}
.gcsc-more-maybe-branding-root {
  display: none !important;
}
.gsc-control-cse {
  background-color: inherit !important;
  border: none !important;
}
